:root {
    /* basic colors*/
    --main-color: #000000;
    --main-secondary-color: #ffffff;
    --main-font-color: #000000;
    --main-font-secondary-color: #F2F2F2;
    --main-bg-color: #ffffff;
    --main-bg-primary-color: #EBE6E6;
    --main-error-color: #f04949;
    --main-error-new-color: #B8320D;
    --main-active-button-color: #B8320D;
    --main-disabled-bg-color: #E6E6E6;
    --main-disabled-inner-color: #B3B3B3;
    --main-border-color: #808080;
    --main-aprove-color: #8BC5B6;
    --main-card-bg-color: #F2F2F2;
    --main-shadow-basic-color: #000000;
    --main-footer-background-color: #333333;
    --main-footer-title-color: #EF4618;
    --main-overlay-color: rgba(51, 51, 51, 0.5);
    /* header colors */
    --header-nav-bg-color: var(--main-bg-primary-color);
    --header-nav-item-color: var(--main-font-color);
    --header-bg-color: var(--main-bg-color);
    --header-nav-link-color: var(--main-color);
    --header-item-color: var(--main-color);
    --header-search-bg-color: var(--main-bg-color);
    --header-search-font-color: var(--main-font-color);
    --header-search-border-color: var(--main-color);
    /* mobile header colors */
    --mobile-header-bg-color: var(--main-bg-primary-color);
    --mobile-header-item-color: var(--main-secondary-color);
    --mobile-header-item-secondary-color: var(--main-color);
    --mobile-header-search-bg-color: var(--main-bg-color);
    --mobile-header-search-font-color: var(--main-secondary-color);
    --mobile-header-search-border-color: var(--main-color);
    --mobile-cart-item-color: var(--main-font-color);
    --mobile-cart-action-color: var(--main-color);
    /* footer colors */
    --footer-bg-color: var(--main-footer-background-color);
    --footer-line-color: #e0e6e8;
    --footer-item-color: #ffffff;
    --footer-title-color: var(--main-footer-title-color);
    /* card colors */
    --card-bg-color: var(--main-bg-color);
    --card-item-primary-color: var(--main-font-color);
    --card-item-secondary-color: var(--main-color);
    --card-action-primary-color: var(--main-color);
    --card-action-secondary-color: var(--main-secondary-color);
    --card-font-color: var(--main-font-color);
    --card-font-color-70: color-mod(var(--card-font-color) alpha(70%));
    /* modal card colors and childModal.vue component */
    --modal-card-bg-color: var(--main-bg-color);
    --modal-card-item-primary-color: var(--main-font-color);
    --modal-card-item-secondary-color: var(--main-secondary-color:);/*?:?*/
    --modal-card-action-primary-color: var(--main-color);
    --modal-card-action-secondary-color: var(--main-secondary-color);
    /* dropdown cards colors */
    --dropdown-card-bg-color: var(--main-bg-color);
    --dropdown-card-action-color: var(--main-color);
    --dropdown-card-item-color: var(--main-font-color);
    --dropdown-card-button-bg-color: var(--main-color);
    --dropdown-card-button-font-color: var(--main-secondary-color);
    /* sidebars colors */
    --sidebar-bg-color: #F5F5F5; /*var(--main-bg-color);*/
    --sidebar-item-color: #333333; /*var(--main-color);*/
    --sidebar-button-bg-color: var(--main-color);
    --sidebar-button-font-color: var(--main-secondary-color);
    /* slider colors */
    --slider-action-primary-color: var(--main-color);
    --slider-action-secondary-color: var(--main-font-color);
    /* special colors */
    --action-item-primary-color: var(--main-color);
    --action-item-secondary-color: var(--main-secondary-color);
    --input-font-color: var(--main-font-color);
    --search-font-color: var(--main-font-color);
    --block-title-color: var(--main-font-color);
    --checkbox-color: var(--main-color);
    /* profile colors */
    --profile-bg-content: var(--main-card-bg-color);
    --profile-active-link: var(--main-footer-title-color);
    --profile-main-font-color: var(--main-font-color);
    --profile-aprove-color: var(--main-aprove-color);
    --profile-main-error-color: var(--main-error-color);
    /* app-input */
    --input-default-color: var(--main-border-color);
    --input-active-color: var(--main-font-color);
    --input-disabled-color: var(--main-disabled-bg-color);
    --input-disabled-inner-color: var(--main-disabled-inner-color);
    --input-error-color: var(--main-error-new-color);
    /* app-select */
    --select-main-color: var(--main-border-color);
    --select-font-color: var(--main-font-color);
    --select-list-bg-color: var(--main-card-bg-color);
    --select-list-hovered-color: var(--main-bg-primary-color);
    --select-active-item-checkmark: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.16797 9.16634L9.16797 14.1663L17.5013 5.83301' stroke='%23333333' stroke-width='1.66667' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    /* app-radio-input */
    --input-radio-default-color: var(--main-border-color);
    --input-radio-active-color: var(--main-footer-title-color);
    --input-radio-disabled-color: var(--main-disabled-bg-color);
    /* app-button */
    --button-bg-color: var(--main-footer-title-color);
    --button-bg-hovered-color: var(--main-secondary-color);
    --button-bg-active-color: var(--main-active-button-color);
    --button-bg-disabled-color: var(--main-disabled-bg-color);
    --button-font-color: var(--main-secondary-color);
    --button-font-hovered-color: var(--main-font-color);
    --button-font-active-color: var(--main-secondary-color);
    --button-font-disabled-color: var(--main-border-color);
    /* app-counter */
    --counter-border-color: var(--main-font-color);
    --counter-default-color: var(--main-footer-title-color);
    --counter-active-color: var(--main-active-button-color);
    --counter-icon-hovered-color: var(--main-font-secondary-color);
    /* shadows */
    --shadow: 0px 3px 5px 5px rgba(230, 230, 230, 0.456);
    --shadow-card: 10px 10px 0px 0px #33333366;
    --shadow-hover: 0px 10px 10px 0px rgba(230, 230, 230, 0.656);
    --slide-overlay: linear-gradient(
            to bottom,
            rgba(237, 237, 237, 0) 0%,
            var(--main-bg-color) 100%
    );
    --image-ellipsis: linear-gradient(
            to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, .2) 20%,
            rgba(255, 255, 255, .8) 60%,
            var(--main-bg-color) 100%
    );
    --image-bg-color: linear-gradient(
            315deg,
            color-mod(var(--main-bg-color) lightness(40%)) 0%,
            var(--main-bg-color) 74%
    );
    /* special variables */
    --breadcrumbs-main-color: var(--main-secondary-color);
    --breadcrumbs-active-color: var(--main-color);
    --breadcrumbs-divider: '/';
    /* skeleton placeholder */
    --placeholder-color: #d9d9d9;
    --placeholder-image: linear-gradient(315deg, #d9d9d9 0%, #f6f2f2 74%);
    --skeleton-bg: var(--main-border-color);
    --skeleton-gradient: linear-gradient(100deg,
                            rgba(255, 255, 255, 0) 0%,
                            rgba(255, 255, 255, 0.3) 50%,
                            rgba(255, 255, 255, 0) 100%);
}
